

import Contact from "@/components/Contact.vue";
import Legal from "@/components/Legal.vue";
import Coverphoto from "@/components/Coverphoto.vue";
import WhoWeAre from "@/components/WhoWeAre.vue"
import MediaDisplay from "@/components/MediaDisplay.vue";

import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import GuiDisplay from "@/components/GuiDisplay.vue";
import LightingDemo from "@/components/LightingDemo.vue";
import RevealSlider from '@/components/RevealSlider.vue';

library.add(faChevronUp)
export default {
    components: {
    Contact,
    Legal,
    Coverphoto,
    FontAwesomeIcon,
    WhoWeAre,
    MediaDisplay,
    GuiDisplay,
    LightingDemo,
        RevealSlider
},
    data() {
        return {
            coverText: "For over 25 years we have been providing custom engineered solutions for the Marine Industry.  Our Staff of Designers and Engineers have the experience and creative prowess to deliver."
        }
    },
    methods: {

    },
    mounted() {
        window.addEventListener("resize", ()=>{
            //figure out width of div, figure out the width of display, sub width of div from display & / 2
            //set padding to final value

            // const diff = (- this.$refs.home.getBoundingClientRect().width) / 2;
            // console.log(diff)
            // this.$refs.home.style.padding = `0px ${diff}px`
        })
    }
}
