
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import {faFacebookF, faYoutube, faLinkedinIn, faInstagram} from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faChevronDown, faFacebookF, faYoutube, faLinkedinIn, faInstagram)

export default {
    components: {
        FontAwesomeIcon
    },
    mounted() {
        setTimeout(()=>{
            //set the size of the cover container here based off the height of the coverPhoto
            // const height = this.$refs.photo.clientHeight;
            // this.$refs.container.style.height = `${height}px`
            const vid = document.getElementById('video') as HTMLVideoElement
            if(vid){
                vid.load()
                vid.addEventListener('loadeddata', ()=>{
                    if(vid.readyState >= 3){
                        vid.style.zIndex = '3';
                        vid.play()
                    }
                })
            }
        }, 100)
    },
}

