<template>
    <div class="legalContainer">
        <a class="item smaller" href="#learnMore">Contact Us</a>
        <a class="item smaller" href="https://ideasthatfloat.com/">Property of Interactive Technologies, Inc.</a>
        <a class="item smaller" href="#about"> About</a>
    </div>
</template>
<style scoped lang="scss">
.legalContainer {
    padding: 50px 0;
    width: 100%;
    height: fit-content;
 
    display: flex; flex-direction: row;
    align-items: flex-end;
    position: relative;
    
    &:after {
        position: absolute;
        content: '';
        bottom: 20px;
        left: 0;
        border-bottom: 2px solid #212121;
        width: 100%;
    }
    &:before {
        position: absolute;
        content: '';
        top: 20px;
        left: 0;
        border-bottom: 2px solid #212121;
        width: 100%;
    }

    & .item {
        font-size: 1.75rem;
        color: white;
        text-decoration: none;
        text-align: center;
        height:fit-content;
        width: fit-content;
        flex: 1;
        &.smaller {
            font-size: 1rem;
        }
    }
}
@media (max-width: 800px){
    .legalContainer {
        width: 95%;
        height: 1.75rem;
        margin:0 auto;
        flex-direction: column;
        height: auto;
        & .item {
            text-align: center;
            width: 100%;
            margin: 5px 0;
        }
        // & .item:nth-child(1){
        //     text-align: center;
        // }
        // & .item:nth-child(2){
        //     display: none;
        // }
        // & .item:nth-child(3){
        //     display: none;
        // }
    }
}
</style>