
import AppTitle from '@/components/AppTitle.vue'
export default {
  components: {
    AppTitle
  },
  mounted() {
    // this.$gtag.optIn()
  }
}
