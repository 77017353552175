<template>
    <div class="mediaContainer">
        <img class="floating" :src="require('@/assets/morph.png')" />
        <div class="text">
            <label class="title">Upgrade Your<br><span>Experience</span></label>
            <p class="info">
                Modern boats are being outfitted with more sophisticated electronics and more screens than modern cars. In addition, it’s not uncommon for boats to be outfitted with as many as six supercharged, monstrous outboards. Unfortunately, it’s very common for these floating masterpieces to be accompanied by boring digital graphics that look like they were designed a decade ago. 

                <br><br>It’s time for your instrument cluster, digital switching, lighting control and multimedia interface to complement the styling of your boat rather than detract from it. It’s time for you to have control over the digital representation of your boat so it is uniquely you. It’s time for iCandy!
            </p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.mediaContainer {
    width: 100%;
    min-height: 720px;
    position: relative;


    & .floating {
        position: absolute;
        left: 0; top: -70px;
        width: 60%;
        height: fit-content;
height: intrinsic;
    }
    & .text {
        position: relative;
        margin-left: auto; top: 5%;
        width: 35%;
        height: 100%;
        display: flex; flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        & .title {
            width: 100%;
            height: fit-content;
            height: intrinsic;
            font-size: 4rem;
            font-family: 'FreeSans';
            font-weight: 800;
            color: rgba(0,0,0,0.6);
            line-height: 3.4rem;
            text-align: start;
            & span{
                color: rgba(255,255,255,0.6);
            }
        }
        & .info {
            width: 100%;
            height: fit-content;
            height: intrinsic;
            overflow-wrap: break-word;
            font-family: 'FreeSans';
            font-weight: 500;
            color: white;
            font-size: 1.5rem;
            text-align: start;
        }
    }
}
@media (max-width: 800px){
    .mediaContainer {
        & .floating {
            position: relative;
            width: 100%;
            top: -40px;

        }
        & .text {
            width: 90%;
            left: 0; right: 0; margin: 0 auto;
        }
    }
}
</style>