
export default {
    name: "RevealSlider",
    data() {
        return {
            showWidth: 0, 
            sliderOffset: 0, //0 start, 1143.24 end
            startOffset: 0,
            start: {x: 0, y: 0},
            container: null as HTMLElement | null,
            touching: false,
            up: 0,
            right: 0,
            width: '1200px', height: '400px',
            hiding: false,
        }
    },
    mounted() {

        //call some window event handler shit here
        this.handleWindowChange()

        window.addEventListener('resize', this.handleWindowChange)
        document.addEventListener('scroll', this.handleWindowScroll, {passive: false})
        document.addEventListener('wheel', this.handleWindowScroll, {passive: false})

        const touch = document.getElementById('touchme') as HTMLElement
        if(touch){
            this.container = touch
        }
    },
    methods: {
        handleWindowScroll(ev: Event | WheelEvent) {
            if(this.hiding)
                return;
            

            const dir = (ev instanceof WheelEvent)? ev.deltaY / Math.abs(ev.deltaY): 1
            
            const bounds = this.container.getBoundingClientRect()
            // console.log(window.innerHeight * 0.1, bounds)
            if(Math.abs(bounds.top) < window.innerHeight * 0.1 && (this.right < 1 || this.up < 1)){
                const scrolltooptions = { top: window.scrollY + bounds.top, behavior: 'instant' };
                window.scrollTo(scrolltooptions as unknown as ScrollToOptions);
            }
            if(bounds.top === 0 && (this.right < 1 || this.up < 1)){
                this.right += (this.right < 1)? 0.04 * dir: 0
                this.up += (this.right >=  1)? 0.04 * dir: 0
                if(this.right < 0)
                    this.right = 0
                if(this.up < 0)
                    this.up = 0
            }

            if(ev instanceof WheelEvent && !(bounds.top === 0 && (this.right < 1 || this.up < 1)) ){
                const scrolltooptions = { top: window.scrollY + ev.deltaY, behavior: 'instant' };
                window.scrollTo(scrolltooptions as unknown as ScrollToOptions);
            }

            ev.stopPropagation()
            ev.stopImmediatePropagation()
            ev.preventDefault() 

            return false
        },
        handleWindowChange() {
            let width = 0;
            if(window.innerWidth > 1280){
                width = 1280
            }
            else {
                this.hiding = true;
                width = window.innerWidth
            }


            this.width = '100%';
            this.height = '100%'
        }
    }
} 
