
import emailjs from 'emailjs-com'
export default {

    mounted(){
        setTimeout(()=>{
            

            const submit = document.getElementById('submit')
            if(submit){
                emailjs.init('VLlJh5nH5SZrB0-WN')
                submit.addEventListener('click', ()=>{

                    if(this.$refs.user_name.value.replace(" ","").length === 0){
                        this.$refs.submit.value = "Please Enter Your Name"
                        this.$refs.submit.style.backgroundColor = "red"
                        this.$refs.submit.style.border = "2px solid red"
                        return;
                    }

                    //check to make sure email is valid
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.$refs.user_email.value)) {
                        //this.msg['email'] = 'Please enter a valid email address';
                        this.$refs.submit.value = "Send Email"
                        this.$refs.submit.style.backgroundColor = "white"
                        this.$refs.submit.style.border = "2px solid white"
                    } else {
                        //this.msg['email'] = '';
                        //console.log('fail')
                        this.$refs.submit.value = "Please Enter a Valid Email"
                        this.$refs.submit.style.backgroundColor = "red"
                        this.$refs.submit.style.border = "2px solid red"
                        
                        return;
                    }
                    

                    emailjs.sendForm('service_8bigk35', 'contact_us', this.$refs.form)
                    .then((result) => {
                        console.log('SUCCESS!', this.$refs.form);
                    }, (error) => {
                        console.log('FAILED...', error.text);
                    });


                    this.$refs.user_email.value = ""
                    this.$refs.message.value = ""
                    this.$refs.user_name.value = ""
                })
            }
            
        }, 100)
    },
    
}
