
export default {
    name: 'LightingDemo',
    data() {
        return {
            highlightColor: 'hsl(10,50%,100%)',
            currentRotate: 0,
        }
    },
    mounted() {
        this.startSpin();
    },
    methods: {
        startSpin() {
            
            this.currentRotate += 1;
            this.highlightColor = 'hsl('+ this.currentRotate +', 100%, 50%)'
            setTimeout(this.startSpin, 25)
        },
    }
}

